import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web';
import i18n from 'i18next';

const detectedLang = i18n.language || 'en-US';

const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
    return null;
  };
  
  export const generateCookieId = (): string => {
    const cookieId = getCookie('visitor_id');
    if (cookieId) {
      return cookieId;
    } else {
      const newCookieId = `visitor_${new Date().getTime()}_${Math.random().toString(36).substring(2, 15)}`;
      document.cookie = `visitor_id=${newCookieId}; path=/; max-age=31536000`; 
      return newCookieId;
    }
  };
    

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_KEY;
if (!instrumentationKey) {
  throw new Error("Application Insights instrumentation key is not defined");
}

// Export a function to track events
export const trackEvent = (eventName: string, properties?: { [key: string]: any }): void => {
  appInsights.trackEvent(
    { name: eventName },
    { ...properties, language: detectedLang } // Include language in properties
  );
};

const appInsights: ApplicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
  },
});
appInsights.loadAppInsights();
export default appInsights;
