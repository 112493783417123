import { Icon, IconButton, Stack, Text } from "@fluentui/react";
import { DrawerHeader, makeStyles } from "@fluentui/react-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./ChatbotStyles.scss";
import { generateCookieId, trackEvent } from '../../utilities/AppInsights';

interface HeaderProp {
  togglePanel: () => void;
}

const useStyles = makeStyles({
  
  cancelIcon: {
    size: "16px",
    color: "#000000",
  },
  robotIcon: {
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "40px",
    textAlign: "center",
    color: "#0078D7",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    },

   
    image: {
        position: "relative",
        top:"3px"
    }
});

const CancelIcon: FC<HeaderProp> = ({ togglePanel }) => {
    const styles = useStyles();
    const images = (require as any).context("../../assets", true);
    const clipicon = "./Clippath.png";
    const clippath = images(clipicon);
    const { t, i18n } = useTranslation();

    const logEvent =() => {
        trackEvent('Chatbot closed', {
            visitorId: generateCookieId()
        });    };

  return (
    <>
          <DrawerHeader className="headerContainer">
              <Stack horizontal horizontalAlign="end" style={{ justifyContent: "space-between" }}>
                  <Stack horizontal >
                     <span>
                          <img
                              src={clippath}
                              alt="logo"
                              className={styles.image }
                          />
                      </span>
                      <span className="buttonText">
                      {t('ChatBot.Navigator')}
                      </span>          
                  </Stack>
                  <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      ariaLabel="Close"
                      onClick={() => {
                          togglePanel(); 
                          logEvent(); 
                      }}
                      className={styles.cancelIcon}
                  />
        </Stack>
      </DrawerHeader>
    </>
  );
};

export default CancelIcon;
