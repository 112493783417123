import "./App.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams
} from "react-router-dom";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import MsHeader from "./components/MSHeaderAndFooter/MsHeader";
import MsFooter from "./components/MSHeaderAndFooter/MsFooter";
import LandingPage from "./components/LandingPage";
import { QueryClient, QueryClientProvider } from "react-query";
import { Button } from "@fluentui/react-components";
import { mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import LanguageList from "./components/Languagelisting";
import ErrorPage from "./components/Error";
import { trackEvent } from './utilities/AppInsights';
import appInsights, { generateCookieId } from "./utilities/AppInsights";


const styles = mergeStyleSets({
  chatButton: {
    backgroundColor: "white !important",
    border: " 1px solid #DDDDDD !important",
    boxShadow: "0px 0px 4px 0px #00000040",
    color: "white",
    padding: "16px 20px",
    cursor: "pointer",
    opacity: 1,
    bottom: "20px",
    right: "20px",
    zIndex: 1,
    borderRadius: "8px !important",
    width: "303px",
    minHeight: "64px",
    "&:focus": {
      border: '5px solid black !important',
      outlineOffset: '5px !important',
    },
  },

  buttonText: {
    maxWidth: "210px",
    color: "#1A1A1A",
    fontSize: "18px",
    margin: "10px",
    lineHeight: "24px",
    fontWeight: "700 !important"
  }

});

const queryClient = new QueryClient();

function App() {
  const images = (require as any).context("./assets", true);
  const cta = "./cta.png";
  const clipicon = "./Clippath.png";
  const ctapath = images(cta);
  const clippath = images(clipicon);
  const [showPanel, setShowPanel] = useState(false);
  const [initialText, setinitialText] = useState<string>("");
  const [botAction, setBotAction] = useState<string>("");
  const { t, i18n } = useTranslation();

    const togglePanel = () => {
    setShowPanel(!showPanel);
  };
    const closePanel = () => {
    setShowPanel(false);
  };
  const handleButtonClick = (action: string, text: string) => {
    trackEvent('Chatbot opened', {
      chatbotAction: action,
      visitorId: generateCookieId()
    });
    setBotAction(action);
    setinitialText(text);
    setShowPanel(true);
  };

  const RedirectWithLang = () => {
    const { lang } = useParams();
    const queryString = window.location.search; // Get current query string
    return <Navigate to={`/${lang || ''}${queryString}`} replace />; // Append query string
  };
  const isErrorOrLocalePath = window.location.pathname === "/error" || window.location.pathname === "/locale";

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <MsHeader />
        <FluentProvider theme={webLightTheme}>
          <Router>
            <Routes>
              <Route
                path="/:lang?"
                element={
                  <LandingPage
                    showPanel={showPanel}
                    togglePanel={togglePanel}
                    closePanel={closePanel}
                    botAction={botAction}
                    initialText={initialText}
                    onButtonClick={handleButtonClick}
                    setBotAction={setBotAction}
                    setinitialText={setinitialText}
                  />
                }
              />
              <Route
                path="/locale"
                element={
                  <LanguageList />
                }
              />
              <Route
                path="/error"
                element={
                  <ErrorPage />
                }
              />
              <Route
                path="/:lang/*"
                element={<RedirectWithLang />}
              />
            </Routes>
          </Router>
        </FluentProvider>
        <MsFooter />
      </QueryClientProvider>
      {!isErrorOrLocalePath &&
        <div style={{ display: showPanel ? "none" : "flex" }} className="buttonDiv" >
          <Button className={styles.chatButton} onClick={() => handleButtonClick("freeResponse", "")} type="button" tabIndex={0}>
            <span>
              <img
                src={clippath}
                alt="logo"
              />
            </span>
            <span className={styles.buttonText}>
              {t('ChatBot.Navigator', 'Open the AI Skills Navigator')}
            </span>
            <span>
              <img
                src={ctapath}
                alt="logocta"
              />
            </span>
          </Button>
        </div>
      }
    </div>
  );
}

export default App;
