import React, { useEffect, useState } from "react";
import { Stack, Text, DefaultButton, IconButton } from "@fluentui/react";
import "./AI AssistantStyles.scss";
import banner from "./AI AssistantData.json";
import ChatBotPanel from "../ChatBot/ChatBotPanel";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { Field, Textarea } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { trackEvent } from '../../utilities/AppInsights';
import appInsights, { generateCookieId } from "../../utilities/AppInsights";

interface AIAssistantProps {
    isLoading: boolean;
    onButtonClick: (action: string, text: string) => void;
}


const AIAssistant: React.FC<AIAssistantProps> = ({ onButtonClick, isLoading }) => {
    const images = (require as any).context("./assets", true);
    const [message, setMessage] = useState("");
    const maxLength = 2000;
    const { t, i18n } = useTranslation();
    const [isRTL, setIsRTL] = useState(false);

    // Function to handle changes in the text input field
    const handleChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const newMessage = event.target.value;
        if (newMessage.length <= maxLength) {
            setMessage(newMessage);
        }
    };

    const [showPanel, setShowPanel] = useState(false);
    const togglePanel = () => {

        setShowPanel(!showPanel);

    };


    useEffect(() => {
        setIsRTL(document.body.classList.contains('langdirection-rtl'));
        if (showPanel) {
            setMessage('');
        }
    }, [showPanel]);

    const removeHtmlTags = (str: string) => {
        return str.replace(/<[^>]*>(.*?)<\/[^>]*>|\/(.|\n)+\/[gimuy]*/ig, '');
    }


    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        var sanitizedMessage = removeHtmlTags(message);
        sanitizedMessage = sanitizedMessage.replace(/<[^>]+>/, "");
        trackEvent('UserQuestionsubmitButton', {
            UserQuestionsubmit: sanitizedMessage,
            visitorId: generateCookieId()
        });
        setMessage(sanitizedMessage);
        onButtonClick("freeResponse", message)
        setMessage('');
    };
    const handleButtonClick = (buttonText: string) => {
        trackEvent('DefaultQuestions', {
            DefaultQuestionbutton: buttonText,
            visitorId: generateCookieId()
        });
        onButtonClick("freeResponse", buttonText)

    };
    return (
        <div className="ai">

            <Stack className={commonStyles.container}>
                <Stack horizontal className="form-ai">

                    <div className="width-50 rightpadding">
                        <img src={images(banner.chatIcon)} alt="chat icon" />
                        <Text variant="large" className="text main-heading">
                            {t('AIAssistant.AIAssistantData.0.mainHeading', 'Start here with AI Skills Navigator')}
                        </Text>
                        <Text variant="medium" className="text description">
                            {t('AIAssistant.AIAssistantData.0.description', 'Ask the AI-powered assistant what learning path is right for your goals.')}
                        </Text>
                    </div>
                    <div className="width-50 formdiv">
                        <Stack className="text ai-assistant-text form-input">
                            <form onSubmit={handleSubmit}>
                                <Field style={{ borderRadius: "8px" }}>
                                    <input type="text"
                                        className="form-input-area"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter" && !event.shiftKey && message.trim() !== "" && message.length <= 2000 && !isLoading) {
                                                event.preventDefault();
                                                handleSubmit();
                                            }

                                        }}
                                        placeholder={t('AIAssistant.AIAssistantData.0.TextfieldPlaceholder', 'Ask a question about learning AI.')}

                                    />
                                    <span style={{ textAlign: "right", color: "#767676" }}>
                                        <IconButton
                                            type="submit"
                                            ariaLabel="Send"
                                            iconProps={{ iconName: isRTL ? "SendMirrored" : "Send" }}
                                            className="form-send-button"
                                            disabled={message.trim() === "" || message.length > 2000 || isLoading}
                                            style={{
                                                backgroundColor:
                                                    message.trim() === "" ? "transparent" : "",
                                            }}
                                        />
                                    </span>
                                </Field>

                            </form>

                            <div className="button-container">
                                <DefaultButton disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}
                                    onClick={() => handleButtonClick(t('AIAssistant.AIAssistantData.0.promptButton.0.buttonText', 'How can I build skills for AI jobs?'))}
                                    className="transparent-button"
                                >
                                    {t('AIAssistant.AIAssistantData.0.promptButton.0.buttonText', 'How can I build skills for AI jobs?')}

                                </DefaultButton>

                                <DefaultButton disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}
                                    onClick={() => handleButtonClick(t('AIAssistant.AIAssistantData.0.promptButton.1.buttonText', ''))}
                                    className="transparent-button"
                                >
                                    {t('AIAssistant.AIAssistantData.0.promptButton.1.buttonText', 'How can I learn AI prompting?')}
                                </DefaultButton>

                            </div>
                        </Stack>
                    </div>

                </Stack>


            </Stack>

        </div>
    );
};

export default AIAssistant;
