//for lower resolution screens
import { FC, useEffect, useRef, useState } from "react";
import { Icon, Stack, mergeStyleSets } from "@fluentui/react";
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import { sections } from "./PageTabNavigator";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./TabListStyles.scss";
import { trackEvent } from '../../utilities/AppInsights';
import appInsights, { generateCookieId } from "../../utilities/AppInsights";

const styles = mergeStyleSets({
  navbarContainer: {
    position: "static",
    top: 0,
    zIndex: 100,
  },
  navbar: {
    width: "100%",
    backgroundColor: "#fff",
    borderBottom: "1px solid #DCDCDC",
  },
  noBorderRadiusClass: {
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  fixed: {
    position: "sticky",
    top: -1,
    width: "100%",
    zIndex: 1000,
  },
});
const menuStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "none",
  fontSize: "14px !important",
  color: "#004275",
  fontWeight: 600,
};

const NavigationMenu: FC = () => {
  const { t, i18n } = useTranslation();
  const [isFixed, setIsFixed] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const navbarRef = useRef(null);
  const [activeItem, setActiveItem] = useState(t(`NavBar.${sections[0].id}`));
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsFixed(offset > 100);
      setIsAtTop(offset === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const handleMenuItemClick = (label: string) => {
    setActiveItem(label);
    setMenuOpen(false);
    trackEvent('MenuNavigationMobileClick', {
      MenuNavigationMobileClick: label,
      visitorId: generateCookieId()
    });
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sectionss = document.querySelectorAll(".section");

      sectionss.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 500 && rect.bottom >= 90) {
          const sectionId = section.id;
          const activeSection = sections.find((sec) => sec.id === sectionId);
            if (activeSection) {           
            setActiveItem(t(`NavBar.${activeSection.id}`));
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={mergeClasses(
          styles.navbarContainer,
          isFixed ? styles.fixed : "",
          isAtTop ? styles.noBorderRadiusClass : ""
        )}
        id="navbar"
        ref={navbarRef}
      >
        <Stack className="mobile-sticky-nav">
          <Menu>
            <MenuTrigger>
              <Button onClick={toggleMenu}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 15px",
                  height: 52,
                  borderTop: 0,
                  borderRight: 0,
                  borderLeft: 0,
                  borderBottom: "4px solid #0074D3",
                  borderBottomLeftRadius: 0,
                  fontSize: "14px",
                  borderBottomRightRadius: 0,
                  color: "#004275"
                }}
              >
                {activeItem}
                <Icon iconName={menuOpen ? 'ChevronUp' : 'ChevronDown'} />
              </Button>
            </MenuTrigger>

            <MenuPopover style={menuStyle} className="nav-item-mobile">
              <MenuList >
                {sections
                  .filter((section) => {
                    const translation = t(`NavBar.${section.id}`, 'null')?.trim();
                    return translation && translation !== 'null';
                  })
                  .map((section) => (
                    <Link to={section.id}
                      key={section.id}
                      offset={-52}
                      smooth={true}
                      duration={600}
                      tabIndex={0}
                      onClick={() => handleMenuItemClick(section.label)}>
                      <MenuItem style={menuStyle}>{t(`NavBar.${section.id}`)} </MenuItem>
                    </Link>
                  ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        </Stack>
      </div>
    </>
  );
};

export default NavigationMenu;
