import React, { useState, useEffect } from 'react';
import { mergeStyleSets, Stack, Text, Link, Icon } from "@fluentui/react";
import { useLearnByTopic } from "../../hooks/useHooks";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { commonStyles } from "../CommonStyles/CommonStyles";
import LearningCard from "../Cards/LearningCard";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './LearnByTopic.scss';
import { trackEvent } from '../../utilities/AppInsights';
import appInsights, { generateCookieId } from "../../utilities/AppInsights";
const styles = mergeStyleSets({

    link: {
        color: "#0074D3", textDecoration: "none", fontSize: "16px", lineHeight: "23px", fontWeight: 600,
        "@media (max-width: 480px)": {
            fontSize: "16px",
        },
    },
    exploreLinks: {
        "@media (max-width: 480px)": {
            fontSize: "16px",
            display: "block !important",
            textAlign: "left"
        },
    },
    rightArrow: {
        position: "relative", top: "0px", marginRight: "20px", fontWeight: 600, left: "5px", textDecoration: "none"

    },
    exploreDiv: {
        marginBottom: "5px"
    },
    showMoreButton: {
        backgroundColor: 'transparent',
        color: '#0078D7',
        border: 'none',
        padding: '10px 0px',
        cursor: 'pointer',
        fontSize: 14,
        borderRadius: 4,
        fontWeight: 600,
        display: 'flex',
        lineHeight: 18.62,
        selectors: {
            ':hover': {
                textDecoration: 'underline'
            },
        },
    },
    arrowIcon: {
        position: "relative",
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 14,
        color: '#0078D7',
        selectors: {
            ':hover': {
                textDecoration: 'none !important'
            },
        },
    }
});

export const LearnByTopic: React.FC = () => {
    const { data, isLoading, error } = useLearnByTopic();
    const [visibleCards, setVisibleCards] = React.useState(4);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [showMore, setShowMore] = useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { lang } = useParams<{ lang?: string }>();
    const navigate = useNavigate();

    const images = (require as any).context("../../assets", true);
    const topicimg = images("./topic.png");
    const isRTL = document.body.classList.contains('langdirection-rtl');
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };
    const toggleShowMore = () => {
        setShowMore(!showMore);
        setVisibleCards(showMore ? 4 : data.length); // Show all cards if showMore is true, otherwise show 4
    };

    useEffect(() => {
        if (error) {
            // Navigate to the error page
            navigate('/error');
        }
    }, [error, navigate, i18n, location]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const heading = t('LearnByTopic.heading', 'null')?.trim();
    const subHeading = t('LearnByTopic.subHeading', 'null')?.trim();
    const linkedin = t('LearnByTopic.ExploreLinks.1.textlink')?.trim();

    // Check for empty, null, or "null" values
    if (!heading || heading === "null" || !subHeading || subHeading === "null" || !data?.length) {
        return null;
    }
    return (
        <Stack className='topicContainer' style={{
            background: ` url(${topicimg}) no-repeat center `,
            backgroundSize: "cover",
        }}>
            <Stack className={commonStyles.container}>
                <>
                    <Stack horizontalAlign="center" style={{ textAlign: "center" }}>
                        <Text className={commonStyles.heading}> {t('LearnByTopic.heading')} </Text>
                        <Text className={commonStyles.subHeading}>{t('LearnByTopic.subHeading')}</Text>
                        <Stack horizontal horizontalAlign="center" className={styles.exploreLinks}>
                            <div className={styles.exploreDiv}>
                                <Link href={t('LearnByTopic.ExploreLinks.0.textlink')} target="_blank" rel="noopener noreferrer" className={styles.link}
                                    onClick={() => {
                                        trackEvent('LearnByTopicExploreLinkOneClick', {
                                            LearnByTopicExploreLinkTextOne: t('LearnByTopic.ExploreLinks.0.text'),
                                            LearnByTopicExploreLinkOne: t('LearnByTopic.ExploreLinks.0.textlink'),
                                            visitorId: generateCookieId()
                                        });
                                    }}>   {t('LearnByTopic.ExploreLinks.0.text')}
                                    {linkedin && linkedin !== "null" && (
                                        <Icon iconName={isRTL ? "ChevronLeft" : "ChevronRight"} className={styles.rightArrow} />
                                    )}
                                </Link>
                            </div>
                            {linkedin && linkedin !== "null" && (
                                <div className={styles.exploreDiv}>
                                    <Link href={t('LearnByTopic.ExploreLinks.1.textlink')} target="_blank" rel="noopener noreferrer" className={styles.link}
                                        onClick={() => {
                                            trackEvent('LearnByTopicExploreLinkTwoClick', {
                                                LearnByTopicExploreLinkTextTwo: t('LearnByTopic.ExploreLinks.1.text'),
                                                LearnByTopicExploreLinkTwo: t('LearnByTopic.ExploreLinks.1.textlink'),
                                                visitorId: generateCookieId()
                                            });
                                        }}> {t('LearnByTopic.ExploreLinks.1.text')}
                                        <Icon iconName="ChevronRight" className={styles.rightArrow} />
                                    </Link>
                                </div>
                            )}
                        </Stack>
                    </Stack>
                </>

                {(isLoading) && <Spinner size={SpinnerSize.large} />}
                {data?.length && !isLoading && (
                    <>
                        <Stack style={{ marginTop: "20px" }}>
                            <Text className={commonStyles.heading}>{t('LearnByTopic.topicHeading')} </Text>
                            <Text className={commonStyles.subHeading}>{t('LearnByTopic.topicSubHeading')}</Text>
                        </Stack>
                        <Stack horizontal className={commonStyles.cardsContainer}>
                            {data.slice(0, isMobile ? visibleCards : data.length).map((x: any, index: number) => (
                                <Stack key={index}>
                                    <LearningCard cardData={x} />
                                </Stack>
                            ))}
                        </Stack>
                        {isMobile && (

                            <button onClick={toggleShowMore} className={styles.showMoreButton}>
                                {showMore ? t('ShowLess') : t('ShowMore')}
                                <Icon iconName={showMore ? 'ChevronUp' : 'ChevronDown'} className={styles.arrowIcon} />
                            </button>

                        )}
                    </>
                )}
            </Stack>
        </Stack>
    );
};
