import {
    Text,
    Stack,
    Icon,
    mergeStyleSets,
    DocumentCardPreview,
    DocumentCard,
    TooltipHost,
} from "@fluentui/react";
import { commonCardStyles } from "./CardStyles";
import "./LearningcardStyle.scss";
import { getCardPreviewProps } from "./CardHelpers";
import { LearningType } from "../../Types/LearningType";
import { Link } from "@fluentui/react-components";
import { trackEvent } from '../../utilities/AppInsights';
import appInsights, { generateCookieId } from "../../utilities/AppInsights";

export type ICardProps = {
    cardData: LearningType;
};

const blogStyles = {
    spotlightBlog: {
        backgroundColor: "#0010A4",
    },
    contentText: {
        marginRight: "10px",
    },
    blogActivity: {
        top: "20px",
        position: "relative",
    },
    icon: {
        fontSize: "9px",
        color: "white",
        marginRight: "5px",
        marginLeft: "5px",
        position: "relative",
        top: "4px",
        left: "6px",
    },
    subTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "5",
        textOverflow: "ellipsis",
        fontSize: "14px",
        color: "#0E1726",
        margin: "6px 10px",
        fontWeigt: 400,

    },
    title: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "#0E1726",
        fontWeight: "700",
        margin: "6px 8px",
        fontWeigt: 600,
    },
    linkFocus: {
        ':focus-visible': {
            outline: "none !important",
            textDecorationStyle: "solid !important",
            textDecorationLine: "underline !important"
        }
    },
    cardTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        textOverflow: "ellipsis",
        fontSize: "13px",
        color: "#0078D4",
        fontWeight: "700",
        padding: "5px 8px",
        paddingTop: "12px",
        fontWeigt: 600,
    },
    linkColor: {
        color: "#0067B8 !important"
    },
    buttonWidth: {
        width: "140px",
        "@media (max-width: 1470px)": {
            width: "100px"
        },
        "@media (max-width: 1310px)": {
            width: "88px"
        },
        "@media (max-width: 1198px)": {
            width: "140px",
        },
        "@media (max-width: 768px)": {
            width: "88px"
        },
        "@media (max-width: 600px)": {
            width: "200px",
        },
        "@media (max-width: 400px)": {
            width: "130px",
        },
    }
};

const styles = mergeStyleSets(blogStyles, commonCardStyles);

const LearningCard: React.FC<ICardProps> = (props) => {
    const { cardData } = props;
    const data = cardData as LearningType;
    const images = (require as any).context("../../assets", true);
    const linkimg = images("./LinkedIn.png");
    const msLearnImg = images("./mslogo.png");
    const isRTL = document.body.classList.contains('langdirection-rtl');

    const kokoroe = images("./kokoroe.png");
    const logoEY = images("./ey.png");
    const logoYT = images("./yt.png");

    const handleCardClick = () => {
        trackEvent('LearningCardexternalLinkClick', {
            LearningCardTittle: data.title,
            LearningCardButtonText: data.buttonText,
            LearningCardexternalLink: data.learnMoreUrl,
            visitorId: generateCookieId()
        });
        window.open(data.learnMoreUrl, "_blank", "noopener,noreferrer");
    };
    return (

        <DocumentCard className={styles.card} onClick={handleCardClick} tabIndex={0}>
            <DocumentCardPreview
                styles={{ root: { borderRadius: "24px" } }}
                {...getCardPreviewProps(data)}
            />
            <Stack className={styles.cardDescription}>

                {/*<TitleForCard cardData={data} />*/}
                <TooltipHost content={data.heading}>
                    <Text className={styles.cardTitle} title={data.heading}> {data.heading}</Text>
                </TooltipHost>
                <TooltipHost content={data.title}>
                    <Text className={styles.title}>{data.title}</Text>
                </TooltipHost>
                <TooltipHost content={data.description}>
                    <Text className={styles.subTitle} >{data.description}</Text>
                </TooltipHost>


                <Stack horizontal horizontalAlign="space-between" style={{ margin: "6px 0px" }}>
                    <Stack.Item >
                        <Link href={data.learnMoreUrl} target="_blank" className={styles.linkFocus} onClick={(e) => e.stopPropagation()} rel="noopener noreferrer">
                            <Stack horizontal >
                                <div className="linkButton">
                                    <Icon iconName={isRTL ? "ChevronLeft" : "ChevronRight"} className={styles.icon} />
                                </div>
                                <span title={data.buttonText} className={`linkText ${styles.linkColor} ${styles.buttonWidth}`} >
                                    {data.buttonText}
                                </span>
                            </Stack>
                        </Link>
                    </Stack.Item>
                    <Stack.Item >
                        <Stack className="linkinButton" horizontal style={{ position: "relative" }} >

                            {data.linkCategory === 'LinkedIn' && (
                                <>
                                <img src={linkimg} alt="logo" style={{ height: "29px" }} />
                                <span className="linkText linkCategory" >
                                {data.linkCategory}
                                </span>
                            </>
                            )}

                            {data.linkCategory === 'Microsoft' && (
                                <>
                                <img src={msLearnImg} alt="logo" style={{ height: "29px" }} />
                                <span className="linkText linkCategory" >
                                {data.linkCategory}
                                </span>
                            </>
                            )}

                            {data.linkCategory === 'Kokoroe' && (
                                <>
                                <img src={kokoroe} alt="logo" style={{ height: "29px" }} />
                                <span className="linkText linkCategory" >
                                {data.linkCategory}
                                </span>
                                </>
                            )}

                            {data.linkCategory === 'EY' && (
                                <img src={logoEY} alt="logo" style={{ height: "29px" }} />
                            )}

                            {data.linkCategory === 'YT' && (
                                <>
                                <img src={logoYT} alt="logo" style={{ height: "30px" }} />
                                <span className="linkText linkCategory" >
                                    YouTube
                                </span>
                            </>
                            )}                           

                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </DocumentCard>
    );
};

export default LearningCard;
