import {  PromptWithHistory } from "../Types/PromptType";
import axiosChatbotClient from "../utilities/ChatbotHttpClient";
import { BotResponse } from "../Types/ResponseType";
import { Feedback, PromptDetails } from "../Types/FeedbackType";
import { getLanguageCodeFromUrl } from "../utilities/LocalizationHelper";
import { t } from "i18next";
var errorCounter = 0;
export const getChatResponse = async (
    userPrompt: PromptWithHistory
): Promise<BotResponse> => {
    try {
        // const token = document.cookie.split('; ')
        //                 .find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1];
        // const res = await axiosChatbotClient.post<BotResponse>("Chat/getChatResponse", userPrompt,
        //      {
        //         headers:{'X-XSRF-TOKEN':token}
        //     });
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        const res = await axiosChatbotClient.post<BotResponse>(`Chat/getChatResponse/${languageCode}`, userPrompt);
        return res.data;
    } catch (error) {
        console.error("Error fetching data:", error);     
        var errorId = getIdForError();
        let errorMessage = `${t('ChatBot.Apologize')}`
        const errorResponse: BotResponse = {
            id: errorId,
            message: errorMessage,
            isAnswered : false,
            followUps: [],
            links: []
        };
        return errorResponse;
    }
};

export const sentFeedback = async (
    feedback: Feedback
): Promise<boolean> => {
    try {
        const res = await axiosChatbotClient.post<boolean>("Feedback/saveFeedback", feedback);
        return res.data;        
    } catch (error) {
        console.error("Error saving data:", error);
        return false;
    }
}

export const loadPromptAndResponse = async (
    promptDetails: PromptDetails
): Promise<boolean> => {
    try {
        const res = await axiosChatbotClient.post<boolean>("Feedback/savePromptAndResponse", promptDetails);
        return res.data;
    } catch (error) {
        console.error("Error saving data:", error);
        return false;
    }
}

const getIdForError = () => {
    errorCounter += 1;
    return `unique-errorId-${errorCounter}`;
};

export const getCannedResponses = async (
    formAnwsers: string[]
): Promise<any> => {
    try {
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        const res = await axiosChatbotClient.post<any>(`Chat/getCannedResponse/${languageCode}`, formAnwsers);
        return res.data;
    } catch (error) {
        console.error("Error fetching data:", error);
       
      
    }
};
  