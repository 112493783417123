import { Button } from "@fluentui/react-components";
import { FormQuestions } from "../../Types/FormQuestions";
import { useState } from "react";
import { generateCookieId, trackEvent } from '../../utilities/AppInsights';
import { getLanguageCodeFromUrl } from "../../utilities/LocalizationHelper";

interface FollowUpQuestionsProps {

    options: FormQuestions;
    onSend: (message: string, isFollowUpClicked?: boolean) => void;
}

const FollowUpQuestions: React.FC<FollowUpQuestionsProps> = ({ options, onSend }) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);


    function sendFollowUp(item: string): void {
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        trackEvent('Followup question clicked', {
            question: item,
            language: languageCode,
            visitorId: generateCookieId()
        });


        onSend(item, true);
        setIsClicked(true);
    }

    return (
        <>
            {options.options.length > 0 && (
                <div className="message-container bot-msg">
                    <div className="follow-up">{options.question}</div>

                    <div style={{ padding: "15px" }}>
                        {options.options
                            .filter((item) => item !== "" && item !== '\r' && item !== '\n' && item !== '\t')
                            .map((item, index) => (
                                <div className="question" key={index}>
                                    <Button
                                        className="longText"
                                        appearance="secondary"
                                        onClick={() => sendFollowUp(item)}
                                        disabled={isClicked}
                                    >
                                        {item}
                                    </Button>
                                </div>
                            ))}
                    </div>

                </div>
            )}
            <div className="timestamp-bot">
                {options.timeStamp}
            </div>
        </>
    );
}

export default FollowUpQuestions;