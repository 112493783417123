import React, { useEffect, useRef, useState } from "react";
import "./ChatbotStyles.scss";
import { IconButton } from "@fluentui/react";
import { Prompt } from "../../Types/PromptType";
import { Feedback, ResponseWithFeedback, UserIpDetails } from "../../Types/FeedbackType";
import { sentFeedback } from "../../services/ChatbotService";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getLanguageCodeFromUrl } from "../../utilities/LocalizationHelper";
import { generateCookieId, trackEvent } from '../../utilities/AppInsights';

interface BotMessageProps {
    userPrompt: Prompt;
    messageWithFeedback: ResponseWithFeedback;
    openModal: (message: Feedback, onSubmit: (updatedFeedback: Feedback) => void) => void; // Pass the callback here
}

const BotMessage: React.FC<BotMessageProps> = ({ userPrompt, messageWithFeedback, openModal }) => {
    const images = (require as any).context("./../../assets", true);
    const dataProtectionImg = "./Data-protection.png";
    const dataProtectionIcon = images(dataProtectionImg);
    const msgRef = useRef<HTMLDivElement | null>(null);
    const [activeButton, setActiveButton] = useState(localStorage.getItem(`activeButton-${messageWithFeedback.id}`) || "");
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (msgRef.current) {
            msgRef.current.innerHTML = messageWithFeedback.response;
        }
    }, [messageWithFeedback]);

    const isActive = (button: any) => {
        return activeButton === button ? "active" : "";
    };
    const fetchIpInfo = async () => {
        try {
            const response = await axios.get('https://ipinfo.io/json');
            return response.data;

        } catch (error) {
            console.error('Error fetching IP info:', error);
        }
    };

    const removeHtmlTags = (text: string) => {
        const anchorPattern = /<a[^>]*>|<\/a>/gi;
        const brPattern = /<br\s*\/?>/gi;
        const whitespacePattern = /[\n\t\r]/g;

        let cleanedText = text.replace(anchorPattern, '');
        cleanedText = cleanedText.replace(brPattern, '');
        cleanedText = cleanedText.replace(whitespacePattern, '');

        return cleanedText.trim();
    };


    const handleLike = async () => {
        setActiveButton("like");
        const languageCode = getLanguageCodeFromUrl(window.location.href);      

        localStorage.setItem(`activeButton-${messageWithFeedback.id}`, "like");
        const ip = await fetchIpInfo();
        const userIpInfo: UserIpDetails = {
            ip: ip.ip,
            country: ip.country,
            region: ip.region,
            city: ip.city,
            location: ip.loc
        }
        const data: Feedback = {
            prompt: userPrompt.prompt,
            response: removeHtmlTags(messageWithFeedback.response),
            feedback: 'Liked',
            UserIpAddress: userIpInfo,
            AdditionalInfo: '',
            Category: "Null",
            Language:languageCode
        };
        trackEvent('User Feedback clicked', {
            question: data.prompt,
            feedback: 'Liked',
            language: languageCode,
            visitorId: generateCookieId()
        });
        try {
            if (activeButton !== 'like') {
                const res  = await sentFeedback(data);
            }
        } catch (error) {
            throw error;
        }
    };


    const handleDislike = async () => {

        const ip = await fetchIpInfo();
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        const userIpInfo: UserIpDetails = {
            ip: ip.ip,
            country: ip.country,
            region: ip.region,
            city: ip.city,
            location: ip.loc
        }
        const data: Feedback = {
            prompt: userPrompt.prompt,
            response: removeHtmlTags(messageWithFeedback.response),
            feedback: 'Disliked',
            UserIpAddress: userIpInfo,
            AdditionalInfo: '',
            Category: '',
            Language:languageCode
        };
        trackEvent('User Feedback clicked', {
            question: data.prompt,
            feedback: 'Disliked',
            language: languageCode,
            visitorId: generateCookieId()
        });

        openModal(data, (updatedFeedback) => {
            setActiveButton("dislike");
            localStorage.setItem(`activeButton-${messageWithFeedback.id}`, "dislike");
        });
    };

    const hideFeedback =
        messageWithFeedback.id === 'Skill-id' ||
        messageWithFeedback.id === 'greeting-id' ||
        messageWithFeedback.id.startsWith('errorSkillsPlan') ||
        messageWithFeedback.id.startsWith('MaliciousPrompt') ||
        messageWithFeedback.id.startsWith('LearningPath') ||
        messageWithFeedback.id.startsWith('NoLearningPath')


    return (
        <>
            <div className="message-container">
                <div className="bot-msg ">
                    <div className=" response">

                        <div id="msg" ref={msgRef} className="msg">
                            {messageWithFeedback.response}
                        </div>

                    </div>

                    {!hideFeedback && (
                        <div className="feedback response">
                            <div className="feedback-question">
                                <b>{t('Feedback.FeedbackText','Was this conversation helpful?')}</b>
                            </div>
                            <div className="feedback-items">
                                <div className="feedback-item">
                                    <IconButton
                                        type="submit"
                                        ariaLabel="Like"
                                        iconProps={{ iconName: "Like" }}
                                        className={`feedbackicon ${isActive("like")}`}
                                        onClick={handleLike}
                                    />
                                    <span className="feedback-text">  {t('Feedback.Yes','Yes')}</span>
                                </div>
                                <div className="feedback-item">
                                    <IconButton
                                        type="submit"
                                        ariaLabel="Dislike"
                                        iconProps={{ iconName: "Dislike" }}
                                        className={`feedbackicon ${isActive("dislike")}`}
                                        onClick={handleDislike}
                                    />
                                    <span className="feedback-text"> {t('Feedback.No','No')}</span>
                                </div>
                            </div>
                        </div>

                    )}
                </div>

            </div>
            <div className="timestamp-bot">
                {messageWithFeedback.timeStamp}
            </div>

        </>
    );
};

export default BotMessage;
