import React, { useState, useEffect } from 'react';
import { Icon, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { useLearnByRole } from "../../hooks/useHooks";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { commonStyles } from "../CommonStyles/CommonStyles";
import LearningCard from "../Cards/LearningCard";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './LearnByRole.scss'
const styles = mergeStyleSets({
    link: {
        color: "#0074D3", textDecoration: "none", fontSize: "16px"
    },
    rightArrow: { position: "relative", top: "3px", marginRight: "10px" },
    showMoreButton: {

        backgroundColor: 'transparent',
        color: '#0078D7',
        border: 'none',
        padding: '10px 0px',
        cursor: 'pointer',
        fontSize: 14,
        borderRadius: 4,
        fontWeight: 600,
        display: 'flex',
        lineHeight: 18.62,
        selectors: {
            ':hover': {
                textDecoration: 'underline'
            },
        },
    },
    arrowIcon: {
        position: "relative",
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 14,
        color: '#0078D7',
        selectors: {
            ':hover': {
                textDecoration: 'none !important'
            },
        },
    }
});

export const LearnByRole: React.FC = () => {
    const { data, isLoading, error } = useLearnByRole();
    const [visibleCards, setVisibleCards] = React.useState(4);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [showMore, setShowMore] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };
    const toggleShowMore = () => {
        setShowMore(!showMore);
        setVisibleCards(showMore ? 4 : data.length);
    };

    useEffect(() => {
        if (error) {
            // Navigate to the error page
            navigate('/error');
        }
    }, [error, navigate]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const topicHeading = t('LearnByRole.topicHeading','null')?.trim();
    const topicSubHeading = t('LearnByRole.topicSubHeading','null')?.trim();
  
    // Check for empty, null, or "null" values
    if (!topicHeading || topicHeading === "null" || !topicSubHeading || topicSubHeading === "null"|| !data?.length ) {
        return null;
    }

    return (
        <Stack className='spotlightContainer' >
            <Stack className={commonStyles.container}>

                {(isLoading) && <Spinner size={SpinnerSize.large} />}
                {data?.length && !isLoading && (
                    <>
                        <Stack style={{ marginTop: "20px" }}>
                            <Text className={commonStyles.heading}> {t('LearnByRole.topicHeading')}</Text>
                            <Text className={commonStyles.subHeading}> {t('LearnByRole.topicSubHeading')}</Text>
                        </Stack>
                        <Stack horizontal className={commonStyles.cardsContainer}>
                            {data.slice(0, isMobile ? visibleCards : data.length).map((x: any, index: number) => (
                                <Stack key={index}>

                                    <LearningCard cardData={x} />

                                </Stack>
                            ))}
                        </Stack>
                        {isMobile && (

                            <button onClick={toggleShowMore} className={styles.showMoreButton}>
                               {showMore ? t('ShowLess') : t('ShowMore')}
                                <Icon iconName={showMore ? 'ChevronUp' : 'ChevronDown'} className={styles.arrowIcon} />
                            </button>

                        )}
                    </>
                )}
            </Stack>
        </Stack>
    );
};
