import React, { useEffect, useState } from 'react';
import './LanguageList.scss';
import axiosClient from "../utilities/HttpClient";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { mergeStyleSets } from "@fluentui/react";
import { trackEvent } from '../utilities/AppInsights';
import appInsights, { generateCookieId } from "../utilities/AppInsights";


interface SupportedLanguage {
    Locale: string;
    EnglishName: string;
    LocalizedName: string;
    Direction: string;
}
const LanguageList: React.FC = () => {
    const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguage[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const styles = mergeStyleSets({
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensure it is above other content
        }
    })

    useEffect(() => {
        fetchSupportedLanguages();
    }, []);

    const fetchSupportedLanguages = async (): Promise<void> => {
        try {
            if (!localStorage.getItem('supportedLanguages')) {
                setLoading(true);
                const response = await axiosClient.get<{ SupportedLanguages: SupportedLanguage[] }>('/BlobConfig/SupportedLanguage');
                const languages = response.data.SupportedLanguages;
                setSupportedLanguages(languages);
                localStorage.setItem('supportedLanguages', JSON.stringify(supportedLanguages));
            } else {
                const cachedLanguages = JSON.parse(localStorage.getItem('supportedLanguages') || '[]');
                setSupportedLanguages(cachedLanguages);
            }
        } catch (error) {
            console.error('Failed to fetch supported languages:', error);
            if (window.location.pathname !== '/error') {
                window.location.href = '/error';
            }
        }
        finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div style={{ padding: '20px', background: '#ffff' }}>
            <h1 style={{ marginBottom: '20px', marginLeft: '4rem', fontSize: '1.5rem', fontWeight: '600', lineHeight: '1.2' }}>
                This site in other countries/regions:
            </h1>
            {loading ? ( // Show loading indicator if data is still loading
                <div><Spinner className={styles.overlay} size={SpinnerSize.large}></Spinner></div>
            ) : (
                <div className="language-grid">
                    {supportedLanguages.map((language, index) => (
                        <a
                            className="language-item"
                            key={index}
                            href={language.Locale}
                            rel="noopener noreferrer"
                            onClick={() => {
                                trackEvent('LanguageChangeClick', {
                                    SelectedLanguage:language.Locale,
                                    visitorId: generateCookieId()
                                });
                            }}
                        >
                            {language.LocalizedName || language.EnglishName} {/* Display localized or English name */}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageList;
