import { Button } from "@fluentui/react-components";
import { FormQuestions } from "../../Types/FormQuestions";
import { getLanguageCodeFromUrl } from "../../utilities/LocalizationHelper";
import { generateCookieId, trackEvent } from '../../utilities/AppInsights';

interface SkillsPlanFormProps {

    options: FormQuestions;
    onSendForm: (message: string, questionOrder: number) => void;
}

const SkillsPlanForm: React.FC<SkillsPlanFormProps> = ({ options, onSendForm }) => {
  
    function sendFormDetails(item: string): void {
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        trackEvent('Skills plan question clicked', {
            question: item,
            language: languageCode,
            visitorId: generateCookieId()
        });

        onSendForm(item, options.questionOrder );

    }

    return (
        <>
    
            {options.options.length > 0 && (
                <div className="message-container bot-msg">
                    <div className="follow-up">{options.question}</div>

                    <div style={{ padding: "15px" }}>
                        {options.options
                            .filter((item) => item !== "" && item !== '\r' && item !== '\n' && item !== '\t')
                            .map((item, index) => (
                                <div className="question" key={index}>
                                    <Button className="longText" appearance="secondary" onClick={() => sendFormDetails(item)}>{item}</Button>
                                </div>
                            ))}
                    </div>

                </div>)}
            <div className="timestamp-bot">
                {options.timeStamp}
            </div> 
        </>
    );
}

export default SkillsPlanForm;